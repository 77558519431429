import { json, type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { useLoaderData, useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import directus from "../server/directus.server";
import { readItems } from "@directus/sdk";
import { Card } from "~/components/EventCard";
import { DateTime } from "luxon";
import i18n from "~/i18next.server";
import { EventSearch } from "~/components/EventSearch";
import { getEventLink } from "~/utils";
import { generateMainPageSchema } from "~/schemas";
import { LuckyButton } from "~/components/LuckyButton";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return [];

  return [
    { title: data.meta.title },
    { name: "description", content: data.meta.description },
    { name: "keywords", content: data.meta.keywords },
    { name: "robots", content: "index, follow" },
    { name: "canonical", content: data.meta.canonicalUrl },

    // Open Graph tags
    { property: "og:type", content: "website" },
    { property: "og:url", content: data.meta.canonicalUrl },
    { property: "og:title", content: data.meta.title },
    { property: "og:description", content: data.meta.description },
    { property: "og:site_name", content: data.meta.websiteName },

    // Twitter Card tags
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:title", content: data.meta.title },
    { name: "twitter:description", content: data.meta.description },

    // Additional meta tags
    { name: "language", content: data.locale },
    { name: "revisit-after", content: "1 day" },
    {
      "script:ld+json": data.schema,
    },
  ];
};

export const headers = {
  "Cache-Control": "public, max-age=1800, s-maxage=3600, stale-while-revalidate=31536000",
  "Vary": "Accept-Language"
};

export const handle = {
  i18n: "common",
};

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const dateParam = url.searchParams.get("date");

  const targetDate = dateParam
    ? DateTime.fromISO(dateParam)
    : DateTime.now();

  const day = targetDate.day;
  const month = targetDate.month;
  const t = await i18n.getFixedT(request);
  const locale = await i18n.getLocale(request);

  const events = await directus.request(
    readItems("events", {
      fields: ["*", "translations.*", "image.filename_disk"],
      filter: {
        _and: [
          { day: { _eq: day } },
          { month: { _eq: month } },
          { translations: { languages_code: { _eq: locale } } }
        ]
      },
      sort: ["-year"],
      deep: {
        translations: {
          _filter: {
            _and: [
              {
                languages_code: { _eq: locale },
              },
            ],
          },
        },
      },
    })
  );

  const eventsCount = await directus.request(
    readItems("events", {
      filter: {
        _and: [
          { translations: { languages_code: { _eq: locale } } }
        ]
      },
      fields: ["id"],
      aggregate: {
        count: "id",
      },
    })
  );

  const recurringEvents = events.filter((event) => event.isRecurring);
  const historicalEvents = events.filter((event) => !event.isRecurring);
  const cdnUrl = process.env.CDN_URL as string;
  const hostUrl = process.env.HOST_URL as string;
  const websiteName = process.env.WEBSITE_NAME as string;

  return json({
    recurringEvents,
    historicalEvents,
    targetDate,
    eventsCount: eventsCount[0]?.count.id ?? 0,
    locale,
    schema: generateMainPageSchema({
      recurringEvents,
      historicalEvents,
      date: targetDate,
      locale,
      hostUrl,
      cdnUrl,
      websiteName, 
      eventsCount: eventsCount[0]?.count.id ?? 0,
      t,
    }),
    meta: {
      websiteName,
      title: t('meta.timeline.title', {
        date: targetDate.toLocaleString({ month: 'long', day: 'numeric' }, { locale })
      }),
      description: t('meta.timeline.description', {
        date: targetDate.toLocaleString({ month: 'long', day: 'numeric' }, { locale })
      }),
      keywords: t('meta.timeline.keywords', {
        date: targetDate.toLocaleString({ month: 'long', day: 'numeric' }, { locale })
      }),
      canonicalUrl: `${process.env.HOST_URL}/`,
    }
  });
}

export default function Index() {
  const { recurringEvents, historicalEvents, eventsCount, targetDate, locale, hostUrl } = useLoaderData<typeof loader>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = DateTime.fromISO(targetDate ?? DateTime.now().toISO());

  const handleDateChange = (date: DateTime) => {
    if (date.toISODate() === DateTime.now().toISODate()) {
      navigate('/');
    } else {
      navigate(`/?date=${date.toISODate()}`);
    }
  };

  const hasNoEvents = recurringEvents.length === 0 && historicalEvents.length === 0;

  return (
    <>
      <section className="relative">
        <div className="container mx-auto px-4 py-8 max-w-4xl">
          <div className="mb-20 text-center">
            <h1 className="text-4xl font-bold mb-4">
              {t('welcome.title')}
            </h1>
            <p className="text-lg text-gray-400 dark:text-gray-300 mb-6 max-w-3xl mx-auto">
              {t('welcome.description', { count: eventsCount })}
            </p>
            <div className="flex flex-wrap gap-4 justify-center text-sm text-gray-500 dark:text-gray-400">
              <span className="flex items-center gap-2">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                {t('welcome.features.verified')}
              </span>
              <span className="flex items-center gap-2">
                <span className="w-2 h-2 bg-yellow-500 rounded-full" />
                {t('welcome.features.aiGenerated')}
              </span>
              <span className="flex items-center gap-2">
                <span className="w-2 h-2 bg-blue-500 rounded-full" />
                {t('welcome.features.community')}
              </span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-8">
            <h2 className="text-2xl md:text-3xl font-bold">
              {t('index.eventsOnDate', {
                date: date.toLocaleString({ month: 'long', day: 'numeric' }, { locale })
              })}
            </h2>

            <div className="flex flex-wrap gap-2 md:gap-3">
              {date.toISODate() !== DateTime.now().toISODate() && (
                <button
                  type="button"
                  onClick={() => handleDateChange(DateTime.now())}
                  className="px-3 md:px-4 py-2 text-sm bg-slate-700 rounded-lg hover:bg-slate-600 transition-colors"
                >
                  {t('index.today')}
                </button>
              )}
              {date.toISODate() !== DateTime.now().minus({ days: 1 }).toISODate() && (
                <button
                  type="button"
                  onClick={() => handleDateChange(DateTime.now().minus({ days: 1 }))}
                  className="px-3 md:px-4 py-2 text-sm bg-slate-700 rounded-lg hover:bg-slate-600 transition-colors"
                >
                  {t('index.yesterday')}
                </button>
              )}
              {date.toISODate() !== DateTime.now().plus({ days: 1 }).toISODate() && (
                <button
                  type="button"
                  onClick={() => handleDateChange(DateTime.now().plus({ days: 1 }))}
                  className="px-3 md:px-4 py-2 text-sm bg-slate-700 rounded-lg hover:bg-slate-600 transition-colors"
                >
                  {t('index.tomorrow')}
                </button>
              )}
            </div>
          </div>

          <div className="mb-6">
            <EventSearch />
          </div>

          {hasNoEvents ? (
            <div className="text-center py-12">
              <div className="text-gray-400 dark:text-gray-300 mb-4">
                {t('index.noEventsMessage', {
                  date: date.toLocaleString({ month: 'long', day: 'numeric' }, { locale })
                })}
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('index.workInProgress')}
              </p>
              <div className="flex justify-center mt-4">
                <LuckyButton />
              </div>
            </div>
          ) : (
            <>
              {/* Recurring Events Section */}
              {recurringEvents.length > 0 && (
                <div className="mb-12">
                  <h2 className="text-2xl font-semibold mb-6">{t('index.recurringEvents')}</h2>
                  <div className="flex flex-col gap-6">
                    {recurringEvents.map((event) => (
                      <Card
                        key={event.id}
                        date=''
                        title={event.translations[0].title}
                        description={event.translations[0].header}
                        image={`https://cdn.whatwhen.day/${event.image?.filename_disk}`}
                        status={event.translations[0].status}
                        to={getEventLink(event)}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Historical Events Section */}
              {historicalEvents.length > 0 && (
                <div>
                  <h2 className="text-2xl font-semibold mb-6">{t('index.historicalEvents')}</h2>
                  <div className="flex flex-col gap-6">
                    {historicalEvents.map((event) => (
                      <Card
                        key={event.id}
                        date={`${event.year}`}
                        title={event.translations[0].title}
                        description={event.translations[0].header}
                        image={`https://cdn.whatwhen.day/${event.image?.filename_disk}`}
                        status={event.translations[0].status}
                        to={getEventLink(event)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
}
