import { useState } from "react";
import { useNavigate } from "@remix-run/react";
import { Input } from "./Input";
import { useTranslation } from "react-i18next";
import { useDebounce } from "../hooks/useDebounce";
import { getEventLink } from "~/utils";
import type { Event } from "~/server/directus.server";
import { trackEvent } from "~/utils/analytics";

export function EventSearch() {
  const [results, setResults] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchEvents = async (query: string) => {
    if (!query) {
      setResults([]);
      return;
    }

    setIsLoading(true);
    trackEvent("search", { query });
    
    try {
      const response = await fetch(`/api/search?q=${encodeURIComponent(query)}`);
      const data = await response.json();
      setResults(data.events);
    } catch (error) {
      console.error("Search error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useDebounce(searchEvents, 300);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const handleResultClick = (result: Event) => {
    navigate(getEventLink(result));
    setResults([]);
  };

  const getStatusIndicator = (status: "AI_GENERATED" | "VERIFIED" | "COMMUNITY_VERIFIED") => {
    switch (status) {
      case "VERIFIED":
        return <span className="w-2 h-2 bg-green-500 rounded-full" />;
      case "COMMUNITY_VERIFIED":
        return <span className="w-2 h-2 bg-blue-500 rounded-full" />;
      default:
        return <span className="w-2 h-2 bg-yellow-500 rounded-full" />;
    }
  };

  return (
    <div className="relative">
      <Input
        type="search"
        placeholder={t('search.placeholder')}
        onChange={handleSearch}
        className="w-full"
      />
      
      {isLoading && (
        <div className="absolute top-full mt-2 w-full bg-slate-800 rounded-md p-2">
          <div className="text-sm text-slate-400">{t('search.loading')}</div>
        </div>
      )}

      {results.length > 0 && (
        <div className="absolute top-full mt-2 w-full bg-slate-800 rounded-md shadow-lg z-50">
          <ul className="max-h-96 overflow-auto">
            {results.map((result) => (
              <li key={result.id}>
                <button
                  type="button"
                  onClick={() => handleResultClick(result)}
                  className="w-full text-left px-4 py-3 hover:bg-slate-700 transition-colors"
                >
                  <div className="flex items-center gap-2 mb-1">
                    {getStatusIndicator(result.translations[0].status)}
                    <span className="text-sm font-medium text-slate-200">
                      {result.translations[0].title}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-xs text-slate-400">
                      {result.year}
                    </span>
                    {result.translations[0].tags && result.translations[0].tags.length > 0 && (
                      <div className="flex gap-2">
                        {result.translations[0].tags.slice(0, 3).map((tag) => (
                          <span
                            key={tag}
                            className="px-2 py-0.5 text-xs bg-purple-500/10 border border-purple-500/20 rounded-full text-purple-400"
                          >
                            {tag}
                          </span>
                        ))}
                        {result.translations[0].tags.length > 3 && (
                          <span className="text-xs text-slate-400">
                            +{result.translations[0].tags.length - 3}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
} 